@use '/src/styles/variables' as *;
@use '/src/styles/mixings' as *;
@use '/src/styles/animations' as *;

.container {
  padding: 1.3rem 0;
  border-bottom: 1px solid #b7b7b7;
  margin-bottom: 5rem;
  &__form {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0 1rem;
    align-items: end;
    @include breakpoints(tablet) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem 1rem;
    }
    @include breakpoints(mobile) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1rem 0;
    }
  }
}
