@use '/src/styles/variables' as *;
@use '/src/styles/mixings' as *;
@use '/src/styles/animations' as *;

.item {
  width: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 0 3em #848383;
  margin-bottom: 1.5rem;
  @include breakpoints(mobile) {
    flex-direction: column;
    align-items: flex-start;
  }
  &__img {
    border-radius: 20px 0 0 20px;
    width: 50%;
    height: 20rem;
    @include breakpoints(mobile) {
      border-radius: 20px 20px 0 0;
      width: 100%;
      height: 200px;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 2rem;
    @include breakpoints(mobile) {
      margin: 0;
      padding: 1rem;
    }
    &__name {
      margin: 0.5rem 0;
    }
    &__date {
      margin: 0 0 0.5rem 0;
    }
    &__button {
      padding: 0.5rem 3rem;
      border-radius: 10px;
      border: 1px solid rgba(48, 194, 213, 1);
      background-color: transparent;
      color: rgba(48, 194, 213, 1);
      font-weight: 400;
      font-size: 13px;
    }
  }
}
