@use '/src/styles/variables' as *;
@use '/src/styles/mixings' as *;
@use '/src/styles/animations' as *;

.container {
  width: 100%;
  padding: 0 9rem;
  @include breakpoints(tablet) {
    padding: 0 8rem;
  }
  @include breakpoints(mobile) {
    padding: 0 3rem;
  }
}
