@mixin breakpoints($point) {
  @if $point == mobile {
    @media (min-width: 320px) and (max-width: 767px) {
      @content;
    }
  } @else if ($point == tablet) {
    @media (min-width: 768px) and (max-width: 1199px) {
      @content;
    }
  } @else if ($point == largescreens) {
    @media (min-width: 1200px) and (max-width: 2200px) {
      @content;
    }
  }
}
