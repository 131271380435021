@use '/src/styles/variables' as *;
@use '/src/styles/mixings' as *;
@use '/src/styles/animations' as *;

.infoWrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @include breakpoints(mobile) {
    flex-direction: column;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 49%;
    border-radius: 20px;
    box-shadow: 0 0 3em #848383;
    padding: 3rem 3rem 4rem 3rem;
    @include breakpoints(mobile) {
      width: 100%;
      padding: 2rem 2rem 3rem 2rem;
      margin-top: 1.5rem;
    }
  }
}
