.header {
  padding: 1.3rem 0;
  border-bottom: 1px solid #b7b7b7;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  &__title {
    margin-left: 1rem;
  }
}

.button {
  padding: 0.2rem 0.5rem;
  border-right: 1px solid #b7b7b7;
  border: none;
  background-color: transparent;
}
