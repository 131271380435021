@use '/src/styles/variables' as *;
@use '/src/styles/mixings' as *;
@use '/src/styles/animations' as *;

.imagesWrapper {
  width: 100%;
  margin: 1.5rem 0;
  border-radius: 20px;
  box-shadow: 0 0 3em #848383;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 2rem 0;
  &__slider {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.5rem 1.5rem 0 1.5rem;
    &__gallery {
      width: 100%;
      display: flex;
      align-items: center;
      list-style: none;
      @include breakpoints(tablet) {
        display: none;
      }
      @include breakpoints(mobile) {
        display: none;
      }
    }
  }
  &__photo {
    border-radius: 20px 20px 0 0;
    width: 100%;
    height: 70vh;
    opacity: 0;
    animation-name: appear;
    animation-duration: 0.3s;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    @include breakpoints(mobile) {
      height: 30vh;
    }
  }
}

.gallery__photo {
  width: 100%;
  height: 5rem;
  border-radius: 5px;
}

.sliderGalleryItem {
  width: 100%;
  margin: 1rem;
  cursor: pointer;
  &:hover {
    transition: transform 0.3s ease-in-out;
    transform: translate(0%, 0%) scale3d(1.1, 1.1, 1);
  }
}

.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -0.7rem;
  right: 50%;
  width: 3rem;
  @include breakpoints(tablet) {
    position: static;
  }
  @include breakpoints(mobile) {
    position: static;
  }
}
