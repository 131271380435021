.descriptionsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;
  border-radius: 20px;
  box-shadow: 0 0 3em #848383;
  padding: 2rem 2rem 3rem 2rem;
  width: 100%;
}
