* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  ::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  ::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
}

body {
  color: #b7b7b7;
  background-color: rgba(249, 249, 249, 1);
  margin: 0;
  font-family: 'ArialMT', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
