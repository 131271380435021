.container {
  position: relative;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0 0 2rem 0;
}

.button {
  position: absolute;
  bottom: 0.7rem;
  right: 1rem;
  border: none;
  background-color: transparent;
  font-size: 14px;
  cursor: pointer;
}
